//
//
//
//
//
//

export default {
  props: {
    options: {
      type: Object,
      default() {
        return {
          bg_color: 'Primary',
          text_color: 'Light',
          rounded: true,
          icon: 'arrow'
        }
      },
      required: true
    }
  },
  computed: {
    isExternal() {
      return !(this.options.url.startsWith('/') || this.options.url.includes(process.env.SITE_PATH || 'ussc.com.ph'))
    },
    target() {
      if (this.options.new_tab) return '_blank'
      return '_self'
    },
    btnClass() {
      const classes = ['btn section-button']

      if (this.options.bg_color) classes.push('btn-' + this.options.bg_color.toLowerCase())
      if (this.options.text_color) classes.push('text-' + this.options.text_color.toLowerCase())

      return classes.join(' ')
    }
  }
}
